document.addEventListener("DOMContentLoaded", function () {
  initOffCanvas();
});

function initOffCanvas() {
  var menuContainer = document.querySelector(".off-canvas.burger-menu");
  var menuToggleBtns = document.querySelectorAll(
    "#burger-menu, .burger-menu__close"
  );

  var bookingContainer = document.querySelector(".off-canvas.booking-menu");
  var bookingToggleBtns = document.querySelectorAll(
    '[data-toggle="booking-menu"], .booking-menu__close'
  );

  handleOffCanvas(menuContainer, menuToggleBtns);
  handleOffCanvas(bookingContainer, bookingToggleBtns);

  function handleOffCanvas(container, toggleBtns) {
    var isActive = false;
    var handleToggle = function (state, container) {
      if (!state) {
        container.style.transform = "translateX(-100%)";
        document.querySelector("body").classList.remove("is-off-canvas-open");
      } else {
        container.style.transform = "translateX(0)";
        document.querySelector("body").classList.add("is-off-canvas-open");
      }
    };

    toggleBtns.forEach(function (btn) {
      btn.addEventListener("click", function () {
        isActive = !isActive;
        handleToggle(isActive, container);
      });
    });
  }
}
